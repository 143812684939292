<template>
  <v-container id="issue-detail" fluid tag="section">
        <dialog-message
          :dialog_message="dialog_message"
          :message="message"
        ></dialog-message>
    <base-material-card
      icon="mdi-clipboard-text"
      :title="$t('vote.manage')"
      class="px-5 py-3"
    >
      <v-col>
        <v-select
          v-model="choose_urban"
          :items="urbans"
          item-text="name"
          item-value="id"
          :label="$t('issue.chooseUrban')"
          @change="getMeeetings()"
          hide-details
          persistent-hint
          return-object
          filled
        ></v-select>
      </v-col>
      <v-col>
        <v-select
          v-model="choose_meetingDate"
          :items="meetingDates"
          item-text="meeting_time"
          item-value="id"
          :label="$t('issue.chooseMeetingDate')"
          @change="getMeeetingIds()"
          hide-details
          persistent-hint
          return-object
          filled
          clearable
        >
          <template slot="selection" slot-scope="{ item }">{{TransDate(item)}}</template>
          <template slot="item" slot-scope="{ item }">{{TransDate(item)}}</template>
        </v-select>
      </v-col>
      <v-col class="pa-0">
        <v-subheader>{{ $t('meeting.meeting_list') }}</v-subheader>
        <v-card elevation=8>
          <v-list class="all-meeting">
            <v-list-item-group color="primary" v-model="choose_meeting">
              <v-list-item v-for="item in showing_meetings" :key="item.id">
                <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col class="text-right">
        <v-btn color="primary" dark class="mb-2" @click="openMeeting()">{{ $t('common.confirm') }}</v-btn>
      </v-col>
    </base-material-card>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  data: vm => ({
    dialog_message: false,
    urbans: [],
    choose_urban: null,
    meetings: [],
    meetingDates: [],
    showing_meetings: [],
    choose_meetingDate: null,
    choose_meeting: 0,
    message: {
      title: "",
      text: ""
    },
  }),
  computed: {
    ...mapState(["token"])
  },
  methods: {
    ...mapMutations(["setTitle"]),
    getUrbans() {
      this.loading = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/urban/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token
        },
        params: {
          //   page: page,
          //   page_size: itemsPerPage,
          //   task: this.id
        }
      };
      this.axios
        .get(url, config)
        .then(function(response) {
          currentObj.loading = false;
          currentObj.setUrbans(response.data);
        })
        .catch(function(error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("get-urban-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    setUrbans(data) {
      this.urbans = data["results"];
    },
    getMeeetings() {
      this.loading = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url =
        process.env.VUE_APP_SERVER_URL +
        "/backend/urban/" +
        this.choose_urban.id +
        "/meetings/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token
        },
        params: {
          //   page: page,
          //   page_size: itemsPerPage,
          //   task: this.id
        }
      };

      this.axios
        .get(url, config)
        .then(function(response) {
          currentObj.loading = false;
          currentObj.setMeetings(response.data);
        })
        .catch(function(error) {
          console.log(error);
          console.log(error.response);
        });
    },
    setMeetings(data) {
      this.meetings = data;
      Object.assign(this.showing_meetings, this.meetings);
      this.meetingDates = [];
      this.meetings.forEach(element => {
        if (this.meetingDates.indexOf(element.meeting_time) < 0) {
          this.meetingDates.push(element.meeting_time);
        }
      });
    },
    getMeeetingIds() {
      this.showing_meetings = [];
      this.choose_meeting = null;
      if (this.choose_meetingDate) {
        this.meetings.forEach(element => {
          if (element.meeting_time == this.choose_meetingDate) {
            this.showing_meetings.push(element);
          }
        });
      } else {
        Object.assign(this.showing_meetings, this.meetings);
      }
    },
    openMeeting() {
      if (this.choose_meeting == null) {
        alert(this.$i18n.t("alert.chooseMeeting"));
        return;
      }
      let meeting = this.showing_meetings[this.choose_meeting];
      this.showIssueList(meeting);
    },
    showIssueList(item) {
      this.$router.push({
        name: "IssueList",
        query: {
          item: item.id
        }
      });
    }
  },
  mounted() {
    this.getUrbans();
    this.setTitle(this.$i18n.t("title.ChooseMeeting"));
  }
};
</script>

<style lang="scss" scoped>
.all-meeting {
  height: 255px;
  overflow-y: auto;
}
</style>